import { imgHost } from "@/utils/config";

export function imageUrl(url) {
  if (url?.length > 0 && url?.indexOf("http") == -1) {
    return `${imgHost}${url}`;
  }
  return url;
}

export function thumbnailUrl(url) {
  if (url?.length > 0 && url?.indexOf("http") > -1) {
    return `${imgHost}${url}`;
  }
  return url;
}

// 列表头像缩略图
export function thumbnailUrlX(url) {
  if (url?.length > 0 && url?.indexOf("www.lgbtbb.hk") > -1) {
    return url + "?x-oss-process=image/resize,m_lfit,h_200,w_200";
  }
  return url;
}
