import { post } from "./request";
import storage from "@/utils/storage";

/// 获取推荐的孕妈
export function getHotSurrogacyList() {
  const params = {
    currentPage: 1,
    pageSize: 3,
    status: 1,
  };
  let skey = "storage-hot-surrogacy";

  return new Promise((resolve, reject) => {
    let storageData = storage.getData(skey);
    if (storageData) {
      resolve(storageData);
    }

    post("/api/frontapi/surrogacy/selectPage", params)
      .then((res) => {
        let resdata = res.data;
        if (res.code == 200) {
          storage.setData(skey, resdata);
          resolve(resdata);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/// 获取孕妈列表
export function getSurrogacyList(params) {
  let isNotSearch =
    !params.nationality && !params.bloodType && !params.ageRange;

  let skey = "storage-list-surrogacy";

  return new Promise((resolve, reject) => {
    if (params.currentPage == 1 && isNotSearch) {
      let storageData = storage.getData(skey);
      if (storageData) {
        resolve(storageData);
      }
    }
    post("/api/frontapi/surrogacy/selectPage", params)
      .then((res) => {
        let resdata = res.data;
        if (res.code == 200) {
          if (params.currentPage == 1 && isNotSearch) {
            storage.setData(skey, resdata);
          }
          resolve(resdata);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/// 获取推荐的捐赠者
export function getHotDonatorList(gender) {
  const params = {
    currentPage: 1,
    pageSize: 3,
    sex: gender,
    status: 1,
  };
  let skey = `storage-hot-donator-${params.sex}`;

  return new Promise((resolve, reject) => {
    let storageData = storage.getData(skey);
    if (storageData) {
      resolve(storageData);
    }

    post("/api/frontapi/donator/selectPage", params)
      .then((res) => {
        let resdata = res.data;
        if (res.code == 200) {
          storage.setData(skey, resdata);
          resolve(resdata);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/// 获取捐赠者列表
export function getDonatorList(params) {
  let isNotSearch =
    !params.nationality && !params.bloodType && !params.ageRange;
  let skey = `storage-list-donator-${params.sex}`;

  return new Promise((resolve, reject) => {
    if (params.currentPage == 1 && isNotSearch) {
      let storageData = storage.getData(skey);
      if (storageData) {
        resolve(storageData);
      }
    }
    post("/api/frontapi/donator/selectPage", params)
      .then((res) => {
        let resdata = res.data;
        if (res.code == 200) {
          if (params.currentPage == 1 && isNotSearch) {
            storage.setData(skey, resdata);
          }
          resolve(resdata);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/// 获取捐赠者详情
export function getDonatorDetail(options) {
  const params = { id: options.id };
  if (options.type == 2) {
    return post("/api/frontapi/surrogacy/detail", params);
  }
  return post("/api/frontapi/donator/detail", params);
}
