<template>
  <div class="donator-item" @click="onClickItem">
    <div class="donator-item__hd">
      <b-img-lazy
        v-bind="mainProps"
        rounded
        :src="avatarUrl"
        alt="image"
      ></b-img-lazy>
    </div>
    <div class="donator-item__bd pl-3">
      <div class="max-lines__1 item__info">
        <span class="info__name">{{ name }}</span>
        <span class="info__tag">{{ tags[0] }}</span>
      </div>
      <div class="max-lines__1">
        <span class="info__title">國籍</span>
        <span class="info__value">{{ country }}</span>
      </div>
      <div class="max-lines__1">
        <span class="info__title">年齡</span>
        <span class="info__value">{{ birthday }}</span>
      </div>
      <div class="max-lines__1">
        <span class="info__title">血型</span>
        <span class="info__value">{{ bloodType }}</span>
      </div>
      <div class="max-lines__1">
        <span class="info__title">身高</span>
        <span class="info__value">{{ bodyHeight }}</span>
      </div>
      <div class="max-lines__1">
        <span class="info__title">體重</span>
        <span class="info__value">{{ bodyWeight }}</span>
      </div>
    </div>
    <div class="donator-item__ft pb-1">
      <div class="btn-contactus" @click.stop="gotoContactus">
        <b-icon icon="envelope"></b-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { imageUrl } from "@/utils/image";

export default {
  props: {
    donator: {
      type: Object,
      default: null,
    },
    // donatorType: {
    //   type: Number,
    //   default: null,
    // },
  },
  data() {
    return {
      mainProps: {
        blankColor: "#eee",
        width: 140,
        height: 140,
        class: "m1",
      },
    };
  },
  computed: {
    avatarUrl() {
      return imageUrl(this.donator?.coverUrl || "");
    },
    name() {
      return this.donator?.code || "";
    },
    tags() {
      var tagStr = this.donator?.tags || "";
      var tagArr = [];
      if (tagStr.length > 0) {
        tagArr = tagStr.split(",") || [];
      }
      return tagArr;
    },
    country() {
      return this.getItemContent(this.donator?.nationalityName);
    },
    birthday() {
      return this.getItemContent(this.donator?.age, { unit: "歲" });
    },
    bloodType() {
      return this.getItemContent(this.donator?.bloodTypeName);
    },
    bodyHeight() {
      return this.getItemContent(this.donator?.height, { unit: "cm" });
    },
    bodyWeight() {
      return this.getItemContent(this.donator?.weight, { unit: "kg" });
    },
    status() {
      return this.donator?.status || 0;
    },
  },
  methods: {
    gotoContactus() {
      let routeData = this.$router.resolve({ name: "Contactus" });
      window.open(routeData.href, "_blank");
      // this.$router.push({
      //   name: "Contactus",
      // });
    },
    getItemContent(content, options) {
      const unit = options?.unit || "";
      var value = content || "";
      if (value.length === 0) {
        value = "待補充";
      } else if (unit !== null) {
        value = value + unit;
      }
      return value;
    },
    onClickItem() {
      let item = this.donator;
      var _type = 0;
      var _id = item.donatorId;
      const _sid = item.surrogacyId;
      if (_sid && !_id) {
        _id = _sid;
        _type = 2;
      }
      var _name = "DonatorDetail";
      if (_type == 2) {
        _name = "SurrogacyDetail";
      }
      this.$router.push({
        name: _name,
        query: { id: _id, type: _type },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.donator-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 15px 0px;
  border-bottom: 1px solid #f0f0f0;

  .donator-item__bd {
    width: 100%;
    // background-color: yellowgreen;

    .item__info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .info__name {
      font-size: 1.1em;
      font-weight: bold;
    }

    .info__tag {
      font-size: 0.5em;
      border: 1px solid #aaa;
      border-radius: 2px;
      margin-left: 0.5em;
      padding: 0em 0.5em;
      // max-width: 66px;
      overflow: hidden;
    }

    .info__value {
      margin-left: 15px;
      color: #888;
    }
  }

  .donator-item__ft {
    width: 60px;
    max-width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .btn-contactus {
      padding: 4px 0px;
      color: #ccc;
      text-align: center;
      font-size: 1.2rem;
    }
  }
}
</style>
