<template>
  <div class="page-error">
    <template v-if="loading">
      <div class="text-center">
        <b-spinner
          variant="primary"
          label="loading"
          style="width: 2rem; height: 2rem"
        ></b-spinner>
        <div class="py-2">加載中...</div>
      </div>
    </template>
    <template v-else-if="loaderror">
      <svg-icon class-name="error__icon" icon-class="nodata" />
      <div v-if="errtips">{{ errtips }}</div>
      <div v-else>加載失敗，請重試</div>
    </template>
    <template v-else>
      <svg-icon class-name="error__icon" icon-class="nodata" />
      <div v-if="status === 'nodata'">暫無數據</div>
      <div v-else>暫無數據</div>
    </template>
  </div>
</template>

<script>
export default {
  name: "PageError",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loaderror: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: null,
    },
    errtips: {
      type: String,
      default: "加载失败",
    },
  },
};
</script>

<style lang="scss" scoped>
.page-error {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #ccc;
  padding-top: 25vh;
  padding-bottom: 45vh;

  .error__icon {
    width: 108px;
    height: 108px;
  }
}
</style>
