import { render, staticRenderFns } from "./PageListFooter.vue?vue&type=template&id=22371926&scoped=true&"
import script from "./PageListFooter.vue?vue&type=script&lang=js&"
export * from "./PageListFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22371926",
  null
  
)

export default component.exports