<template>
  <div v-if="listData.length > 0" class="page-list__footer mt-3">
    <div v-if="isMobile" class="py-3">
      <div v-if="isLoading" class="text-center">
        <b-spinner variant="primary" label="加載中..."></b-spinner>
      </div>
      <div v-else-if="listData.length > 0" class="text-center">
        已顯示所有數據
      </div>
    </div>
    <div v-else-if="pageNum > 1" class="text-center">
      <div v-if="isLoading" class="text-center">
        <b-button class="btn-loadmore" block size="lg" variant="light">
          <b-spinner
            style="width: 1.25rem; height: 1.25rem"
            variant="primary"
            label="加載中..."
          ></b-spinner>
          <span class="ml-2">加載中...</span>
        </b-button>
      </div>
      <template v-else-if="canLoadMore">
        <b-button
          class="btn-loadmore"
          block
          size="lg"
          variant="light"
          @click="clickToLoad"
          >點擊加載</b-button
        >
      </template>
      <div v-else-if="listData.length > 0" class="text-center">
        已顯示所有數據
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PageListFooter",
  props: {
    listData: {
      type: Array,
      default: null,
    },
    pageNum: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    canLoadMore: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.app.isMobile,
    }),
  },
  methods: {
    clickToLoad() {
      this.$emit("loadmore");
    },
  },
};
</script>

<style lang="scss" scoped></style>
